import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import { unmountComponentAtNode } from "react-dom";
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import { validateEmail } from '../utils/HelpfulFunction';
import AnswerItemsComponent from '../../components/small_components/AnswerItemsComponent';
import Confetti from 'react-confetti';
import appBackgroundImage from '../../styles/images/trivia_background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleSponsorLogo from '../../styles/images/sponsor_logo.png'
import sampleAppTopImage from '../../styles/images/top_icon.png';
import ReactPlayer from 'react-player';
import { getDistance } from 'geolib';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import '../../styles/css/modalstyles.css';
import posed, {PoseGroup} from "react-pose";

const Trans = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: ({ delta }) => - delta * 100 + 'vw' }
})

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: sessionStorage.getItem('userEmail') || sessionStorage.getItem('tempToken') || false,
          alerted: false,
          active: false,
          fullName: '',
          seenVideo: false,
          phone: '',
          zip: '',
          tenantRules: {},
          question: 0,
          width: 0,
          height: 0,
          questions: [],
          questionAnswered: false,
          formFilledOut: false,
          videoDuration: "0:00",
          userAnswers: [],
          alreadyUpdate: "",
          currentGameId: null,
          currentGameTime: false,
          currentResponseArray: [],
          startConfetti: false,
          rotationStarted: false,
          locationChecking: true,
          optedIntoEmail: {},
          tierMessaging: [],
          startAnimating: false,
          localAnswersArray: [],
          gameStatus: 0
        };
        this.logout = this.logout.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount() {
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.logUserActivity(key);
            this.removeReactAlert();
            this.setState({
              currentGameId:key,
              startConfetti: false
            }, ()=> {
              this.currentGameFormFilledRef = base.listenTo('currentGameFormFilled/'+ this.state.currentGameId +'/' +base64EncodedEmail, {
                context: this,
                state: 'formFilledOut',
                then(response){
                  if(response.name){
                    document.getElementById('more-info-form').style.display = "none";
                    document.getElementById('thanks-for-playing-section').style.display = ""
                  }
                }
              })
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(userEmail && userEmail.indexOf('@') === -1){
        base64EncodedEmail = userEmail
      }

      this.activeRef = base.bindToState('currentGame/active', {
        context: this,
        state: 'active',
      })

      this.gameStatusRef = base.bindToState('currentGame/status', {
        context: this,
        state: 'gameStatus',
      })

      this.gameStartTimeRef = base.bindToState('currentGame/timeStamp', {
        context: this,
        state: 'currentGameTime',
      })

      this.rotationStartedRef = base.bindToState('currentGame/rotate', {
        context: this,
        state: 'rotationStarted',
      })

      this.editAnswersRef = base.bindToState('currentGame/editAnswers', {
        context: this,
        state: 'editAnswers',
      })

      this.gameTimingRef = base.bindToState('currentGame/gameTiming', {
        context: this,
        state: 'gameTiming',
      })

      this.tierMessagingRef = base.bindToState('tierMessaging', {
        context: this,
        state: 'tierMessaging',
      });

      this.questionRef = base.bindToState('currentGame/questions', {
        context: this,
        state: 'questions',
        asArray: true
      });

      this.gameOverRef = base.bindToState('currentGame/answered', {
        context: this,
        state: 'gameOver'
      });

      this.userAnswersRef = base.bindToState("userAnswers", {
        context: this,
        state: 'userAnswers',
        asArray: true
      });

      this.prizesWonRef = base.listenTo('prizeWon/'+base64EncodedEmail, {
        context: this,
        then(winnerPrize){
         if(this.state.gameTiming !== "immediate"){
           if(winnerPrize === true){
             this.showGameOverMessage(true)
           } else if(winnerPrize === false) {
             this.showGameOverMessage(false)
           }
         }
        }
      });

      this.currentResponseArrayRef = base.listenTo('userAnswers/'+base64EncodedEmail, {
        context: this,
        state: 'currentResponseArray',
        asArray: true,
        then(arrayOfAnswers){
          if(arrayOfAnswers.length === 0 && this.props.variables && this.props.variables.collectDistance && this.props.variables.formattedAddress && this.props.variables.acceptableDistance){
            this.checkUsersLocation();
          } else {
            let numberOfAnswers = arrayOfAnswers.length;
            let questionAnswered = false;
            let hasVideoUrl = false;
            if(numberOfAnswers > 0){
              numberOfAnswers = numberOfAnswers - 1;
              if(numberOfAnswers === this.state.questions.length){
                questionAnswered = true;
                if(this.state.gameTiming === "immediate"){
                    this.startLocalAnswers()
                }
              } else {
                hasVideoUrl = !!this.state.questions[numberOfAnswers].videoLink;
              }
            } else {
              if(this.state.questions[numberOfAnswers]){
                hasVideoUrl = !!this.state.questions[numberOfAnswers].videoLink;
              } else {
                hasVideoUrl = false
              }
            }
            let startAnimating = true
            if(this.state.question !== numberOfAnswers){
              startAnimating = false
            }
            this.setState({
              questionAnswered: questionAnswered,
              question: numberOfAnswers,
              currentResponseArray: arrayOfAnswers,
              locationChecking: false,
              modal: hasVideoUrl,
              startAnimating: startAnimating
            }, ()=>{
              this.setState({
                startAnimating: true
              })
            })
          }
        }
      });

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0)
      this.props.loadPage();
    }

    onEnded(){
      this.setState({
        modal: false
      })
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    componentWillUnmount() {
      base.removeBinding(this.rotationStartedRef);
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.tierMessagingRef);
      base.removeBinding(this.currentResponseArrayRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.questionRef);
      base.removeBinding(this.userAnswersRef);
      base.removeBinding(this.prizesWonRef);
      base.removeBinding(this.gameStartTimeRef);
      base.removeBinding(this.activeRef);
      base.removeBinding(this.gameTimingRef);
      base.removeBinding(this.gameOverRef);
      base.removeBinding(this.editAnswersRef);
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      sessionStorage.removeItem('verifiedAge');
      sessionStorage.removeItem('userEmail');
      sessionStorage.removeItem('birthday');
      sessionStorage.removeItem('locationPermissions');
      this.setState({
        userEmail:false
      })
    }

    setAnswersPrompt(){
      const {stringConstants, variables} = this.props;
      confirmAlert({
        variables: variables,
        title: stringConstants.AREYOUSURETEXT,
        message: stringConstants.NOTABLETOCHANGEPICKSTEXT,
        buttons: [
          {
            label: stringConstants.YESCONFIRMBUTTONTEXT,
            onClick: () => {
              this.setAnswers();
            }
          },
          {
            label: stringConstants.NODISMISSBUTTONTEXT
          }
        ]
      })
    }

  setAnswers(){
    const {stringConstants, variables} = this.props;
    const postToUserAnswers = {};
    const localAnswers = this.state.localAnswersArray;
    let email = this.state.userEmail;
    let base64EncodedEmail = email;
    if(email.indexOf('@') > -1){
      base64EncodedEmail = btoa(email);
    }
    for(const i in localAnswers){
      const localAnswer = localAnswers[i];
      postToUserAnswers["/userAnswers/" + base64EncodedEmail + "/" + localAnswer.questionId] = localAnswer.answerId;
    }
    postToUserAnswers["/userAnswers/" + base64EncodedEmail + '/timeStamp'] = new Date().getTime().toString();
    appDatabasePrimaryFunctions.ref().update(postToUserAnswers, function(error){
      if(!error){
        console.log("Answers Submitted");
        // gridItem.style.backgroundColor = "transparent";
      } else {
        console.log(error)
        confirmAlert({
          variables: variables,
          title: stringConstants.ERRORTEXT,
          message: error.message,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
      }
    })
  }

  setUpQuestionsWithAnswerCount(userAnswers, questions){
      let finalQuestionTally = {};
      let questionIdArray = [];
      for(let questionIndex in questions){
        let question = questions[questionIndex];
        let questionId = question.id;
        finalQuestionTally[questionId] = {};
        finalQuestionTally[questionId].totalPicks = 0;
        finalQuestionTally[questionId].answers = {};
        let questionAnswers = question.answers;
        for(let answerIndex in questionAnswers){
          let answer = questionAnswers[answerIndex];
          if(this.state.immediateAnswersShow && this.state.gameTiming === "immediate"){
            answer.shown = true
          } else if(this.state.gameTiming === "immediate"){
            answer.shown = false
          }
          let answerId = answer.id;
          finalQuestionTally[questionId].answers[answerId] = 0;
        }
        questionIdArray.push(questions[questionIndex].id);
      }
      if(userAnswers.length > 0) {
        for(let answerIndex in userAnswers) {
          let answerObject = userAnswers[answerIndex];
          for(let questionIndex in questionIdArray){
            let questionId = questionIdArray[questionIndex];
            if(answerObject[questionId]){
              finalQuestionTally[questionId].totalPicks++;
              let answerId =  answerObject[questionId];
              finalQuestionTally[questionId].answers[answerId]++;
            }
          }
        }
      }
      return finalQuestionTally;
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      const stringConstants = this.props.stringConstants;
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(stringConstants.GEOLOCATIONNOTSUPPORTED);
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject(stringConstants.NEEDBROWSERPERMISSION);
                } else {
                  console.log(err.message);
                  reject(stringConstants.UNKNOWNERROROCCURED);
                }
              }
          );
        }
      })
    }

    startLocalAnswers(){
      const stringConstants = this.props.stringConstants || {};
      confirmAlert({
        title: stringConstants.FINISHEDTEXT,
        variables: this.props.variables,
        message: stringConstants.YOUANSWEREDALLQUESTIONSTEXT,
        buttons: [
          {
            label: stringConstants.OKTEXT,
            onClick: () => {
              this.setState({
                immediateAnswersShow: true
              })
              this.getPrize()
            }
          }
        ]
      });
    }

    getPrize() {
      const stringConstants = this.props.stringConstants || {};
      const userResponse = {};
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(userEmail && userEmail.indexOf('@') === -1){
        base64EncodedEmail = userEmail
      }
      const vm = this;
      userResponse['userInfo'] = base64EncodedEmail;
      userResponse['tenantVariables'] = this.props.variables || {};
      fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/immediatePrizeCheck`, {
      // fetch(`http://localhost:5001/sqwadtriviademo/us-central1/immediatePrizeCheck`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userResponse),
      }).then(res => {
        vm.setState({
          loading: false
        })
        Promise.resolve(res.json()).then(function (value) {
          if(value.error){
            confirmAlert({
              title: stringConstants.ERRORTEXT,
              variables: this.props.variables,
              message: stringConstants.SOMEERROROCCURED,
              buttons: [
                {
                  label: stringConstants.OKTEXT,
                  onClick: () => {
                    vm.startLocalAnswers();
                  }
                }
              ]
            });
          } else {
            if(value.prizeWon){
              vm.showGameOverMessage(true)
            } else {
              vm.showGameOverMessage(false)
            }
          }
        })
      })
    }

    getLocationPermission(){
      const stringConstants = this.props.stringConstants || {};
      const locationErrorTitle = stringConstants.LOCATIONERRORHEADER;
      this.getLocation().then(distance_meters => {
        sessionStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(!this.props.variables.canPlayOutside && distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
          this.logUserActivity(this.state.currentGameId);
        } else if(this.props.variables.canPlayOutside && distance_meters > allowed_distance) {
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
          this.logUserActivity(this.state.currentGameId);
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          let locationErrorMessage = "Too far from game area to participate!";
          if(this.props.variables.canPlayOutside){
            locationErrorMessage = "Too close game area to participate!";
          }
          if(this.props.variables.notAcceptableLocationMessage){
            locationErrorMessage = this.props.variables.notAcceptableLocationMessage;
          }
          let notAcceptableLocationHeader = locationErrorTitle;
          if(this.props.variables.notAcceptableLocationHeader){
            notAcceptableLocationHeader = this.props.variables.notAcceptableLocationHeader;
          }
          confirmAlert({
            title: notAcceptableLocationHeader,
            variables: this.props.variables,
            message: locationErrorMessage,
            buttons: [
              {
                label: stringConstants.RETRYTEXT,
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        this.setState({
          modal:false,
          loading:false
        });
        sessionStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: stringConstants.RETRYTEXT,
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const stringConstants = this.props.stringConstants || {};
      const variables = this.props.variables;
      const locationPermissions = sessionStorage.getItem('locationPermissions');
      if(!this.props.variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: stringConstants.YESCONFIRMBUTTONTEXT,
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    showGameOverMessage(gotWinningMessage){
      const currentAnswerArray = this.state.currentResponseArray;
      const stringConstants = this.props.stringConstants || {};
      const questions = this.state.questions;
      const answers = [];
      for(const y in questions){
        const questionAnswers = questions[y].answers;
        for(const answerIndex in questionAnswers){
          if(currentAnswerArray.indexOf(questionAnswers[answerIndex].id) !== -1){
            questionAnswers[answerIndex].shown =  questions[y].shown;
            answers.push(questionAnswers[answerIndex])
          }
        }
      }
      let count = 0;
      for(const answerIndex in answers){
        const answer = answers[answerIndex];
        if(answer.correct){
          count+=1
        }
      }
      const tierMessages = this.state.tierMessaging[count];
      let headerHeader = tierMessages.losingHeader;
      let headerMessage = tierMessages.losingMessage;
      if(gotWinningMessage){
        headerHeader = tierMessages.winningHeader;
        headerMessage = tierMessages.winningMessage;
        this.setState({
          startConfetti: true
        })
      } else if (count > 0) {
        headerHeader = tierMessages.winningMissedHeader;
        headerMessage = tierMessages.winningMissedMessage;
        // this.setState({
        //   startConfetti: true
        // })
      }
      confirmAlert({
        variables: this.props.variables,
        title: headerHeader,
        message: headerMessage,
        buttons: [
          {
            label: stringConstants.OKTEXT,
            onClick: () => this.setState({startConfetti:false})
          }
        ]
      })
    }

    logUserActivity(currentGame){
      const vm = this;
      let userEmailToBase64 = "";
      if(currentGame && this.state.userEmail.indexOf('@') > -1){
        const gameid = currentGame;
        userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameid,
              })
              console.log("user game logged!")
            }
          }
        })
      }
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    goToSpecificQuestion(answerClicked){
      let indexToGoTo;
      const questions = this.state.questions;
      for(const questionIndex in questions){
        const question = questions[questionIndex];
        for(const answerIndex in question.answers){
          const answer = question.answers[answerIndex];
          if(answer.id === answerClicked.id){
            indexToGoTo = questionIndex;
          }
        }
      }
      this.setState({
        questionNumber: indexToGoTo,
        editingQuestion: true
      })
    }

    onClickAnswer(answerClicked, position){
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const gridItem = document.getElementById(answerClicked.id);
      const gameStatus = this.state.gameStatus || 0;
      const frozenHeader = tenantVariables.frozenHeader || "Picks Frozen!"
      const frozenMessage = tenantVariables.frozenMessage || "No more picks are allowed!"
      if(gameStatus === 1){
        confirmAlert({
          variables: tenantVariables,
          title: frozenHeader,
          message: frozenMessage,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      if(typeof this.state.currentGameId === "string" && this.state.currentGameId.length > 0){
          this.logUserActivity(this.state.currentGameId);
      }
      // gridItem.style.backgroundColor = sessionStorage.getItem('primaryColor');
      let gameOverTextHeader = tenantVariables.gameOverHeader || "Answers are up!";
      let gameOverTextBody = tenantVariables.gameOverBody || "Sorry you missed answering before the game ended! Come back next game to play again.";
      if(this.state.rotationStarted || this.state.gameOver){
        confirmAlert({
          variables: tenantVariables,
          title: gameOverTextHeader,
          message: gameOverTextBody,
          buttons: [
            {
              label: stringConstants.OKTEXT,
              // onClick: () => gridItem.style.backgroundColor = "transparent"
            }
          ]
        })
        return
      }

      var answerInfo = {};
      // document.getElementsByClassName("grid")
      // document.getElement react-confirm-alert-button-group button
      answerInfo['answerText'] = answerClicked.answerText;
      answerInfo['position'] = position;
      answerInfo['questionId'] = "questionID"
      answerInfo['answerId'] = answerClicked.id;
      answerInfo['timeAnswered'] = Date.now();
      answerInfo['email'] = this.state.userEmail;
      if(this.state.userEmail.indexOf('@') > -1){
        answerInfo['email'] = btoa(this.state.userEmail);
      }
      console.log(answerClicked, position)


      // if(this.state.userEmail.trim() === ""){
      //   alert("This email is strange, please logout and try a different one!")
      //   return;
      // }
      // if(this.state.currentResponse.answerId){
      //   confirmAlert({
      //     title: 'Answer already picked!',
      //     message: "You already have an answer selected so you can't pick another one",
      //     buttons: [
      //       {
      //         label: 'OK',
      //       }
      //     ]
      //   })
      //   return;
      // }
      // this.setState({
      //   currentResponse: answerInfo
      // })
      var base64EncodedEmail = btoa(this.state.userEmail);

      var vm = this;
      var questionId;
      if(this.state.editingQuestion){
        questionId = this.state.questions[this.state.questionNumber].id;
      } else {
        questionId = this.state.questions[this.state.localAnswersArray.length].id;
      }
      var postToUserAnswers = {};
      // https://stackoverflow.com/questions/37954217/is-the-way-the-firebase-database-quickstart-handles-counts-secure/37956590#37956590
      // one day when you want to refactor this with less data being passed around via user answers check out this article on
      // not a perfect solution since I think it could have a higher failure rate on update but if handled well maybe this makes more sense
      // although the more people voting the more failures it would have so a little counter intuitive
      // postToUserAnswers["/userAnswers/" + base64EncodedEmail + "/" + questionId] = answerClicked.id;
      // postToUserAnswers["/userAnswers/" + base64EncodedEmail + '/timeStamp'] = new Date().getTime().toString();
      // // base.post(`userAnswers/${base64EncodedEmail}/${questionId}`, {
      // appDatabasePrimaryFunctions.ref().update(postToUserAnswers, function(error){
      //   if(!error){
      //     console.log("Answer Clicked");
      //     // gridItem.style.backgroundColor = "transparent";
      //   } else {
      //     console.log(error)
      //     confirmAlert({
      //         variables: vm.props.variables,
      //         title: stringConstants.ERRORTEXT,
      //         message: error.message,
      //         buttons: [
      //           {
      //             label: stringConstants.OKTEXT,
      //           }
      //         ]
      //       })
      //   }
      // })
      const localAnswersArray = this.state.localAnswersArray;
      let answerExists = false;
      for(const i in localAnswersArray){
        const localAnswer = localAnswersArray[i];
        if(questionId === localAnswer.questionId){
          localAnswer.answerId = answerClicked.id;
          answerExists = true;
        }
      }
      if(!answerExists){
        localAnswersArray.push({questionId: questionId, answerId: answerClicked.id})
      }
      this.setState({
        localAnswersArray: localAnswersArray,
        editingQuestion: false,
        startAnimating: false
      }, () => {
        // if(!this.state.editAnswers && this.state.localAnswersArray.length === this.state.questions.length){
        //   this.setAnswers();
        // }
        if(this.state.localAnswersArray.length === this.state.questions.length){
          this.setAnswers();
        }
        this.setState({
          startAnimating: true
        }, () => {
          const myDiv = document.getElementById('topOfPage');
          myDiv.scrollTop = 0;
        })
      })
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const playingTextHeader = tenantVariables.playingTextHeader || "Next game starts during";
      const questionTextColor = tenantVariables.questionTextColor || "white";

      return(
        <>
            <div className="hero-text-container">
              <img src={frontLogoImage} className="main-hero-image" alt=""/>
            </div>
            <p style={{display: tenantVariables.playingText ? '' : "none", color:questionTextColor}}>{playingTextHeader}
              <br/>
              <span className="emphasizedText" style={{backgroundColor:primaryColor, color:secondaryColor}}>
                {tenantVariables.playingText}
              </span>
            </p>
        </>
        )
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const currentAnswerArray = this.state.currentResponseArray;
      const editingQuestion = this.state.editingQuestion;
      const currentGameTime = this.state.currentGameTime;
      const questions = this.state.questions || [];
      const gameStatus = this.state.gameStatus || 0;
      const editAnswersText = tenantVariables.editAnswersText || stringConstants.EDITPICKTEXT;
      let answerList = [];
      let question = {};
      let topLeftImage = tenantVariables.topLeftImage || sampleAppTopImage;
      let topAnswerImage = tenantVariables.topAnswerImage || sampleAppTopImage;
      let answers = [];
      let show_percentages = false;
      if(tenantVariables.showFanPercentages === "all"){
        show_percentages = true;
      } else if(tenantVariables.showFanPercentages === "parttime" && this.state.questionAnswered) {
        show_percentages = true;
      }
      const nowInMilli = Date.now();
      const hoursInMillisecnods24 = 86400000;
      let hideAnswerForm = false;
      if(currentGameTime && (currentGameTime + hoursInMillisecnods24) < nowInMilli){
        hideAnswerForm = true
      }
      const primaryColor = tenantVariables.primaryColor || "black"
      const secondaryColor = tenantVariables.secondaryColor || "white"
      const sponsorLogo = tenantVariables.sponsorLogo || sampleSponsorLogo;
      const questionTextColor = tenantVariables.fanQuestionColor || "white";
      const myAnswersText = stringConstants.MYANSWERSTEXT || "My Answers"
      let questionCount = [];
      let videoURL = "";
      // const questionNumber = this.state.question || 0;
      const questionNumber = editingQuestion ? this.state.questionNumber : this.state.localAnswersArray.length || 0;
      const localArrayQuestionAnswers = this.state.localAnswersArray.length === questions.length;
      if(questions.length > 0 && questionNumber < questions.length && (this.state.questionAnswered === false || editingQuestion)){
        question = questions[questionNumber];
        answerList = questions[questionNumber].answers.sort((a,b)=>a.order-b.order);
        questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions);
        videoURL = questions[questionNumber].videoLink;
        show_percentages = false;
      } else if(this.state.questionAnswered === true || localArrayQuestionAnswers){
        for(const y in questions){
          var questionAnswers = questions[y].answers.sort((a,b)=>a.order-b.order);
          for(var answer in questionAnswers){
            if(currentAnswerArray.indexOf(questionAnswers[answer].id) !== -1){
              questionAnswers[answer].shown = questions[y].shown;
              answers.push(questionAnswers[answer])
            } else if(localArrayQuestionAnswers){
              for(const t in this.state.localAnswersArray){
                if(this.state.localAnswersArray[t].answerId === questionAnswers[answer].id && this.state.localAnswersArray[t].questionId === questions[y].id){
                  questionAnswers[answer].shown = questions[y].shown;
                  answers.push(questionAnswers[answer])
                }
              }
            }
          }
        }
        questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions)
      }
      // const localArrayQuestionAnswers = this.state.localAnswersArray.length === questions.length;
      // if(questions.length > 0 && questionNumber < questions.length && this.state.questionAnswered === false){
      //   question = questions[questionNumber];
      //   answerList = questions[questionNumber].answers.sort((a,b)=>a.order-b.order);
      //   questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions);
      //   videoURL = questions[questionNumber].videoLink;
      // } else if(this.state.questionAnswered === true || localArrayQuestionAnswers){
      //   for(const y in questions){
      //     var questionAnswers = questions[y].answers.sort((a,b)=>a.order-b.order);
      //     for(var answer in questionAnswers){
      //       if(currentAnswerArray.indexOf(questionAnswers[answer].id) !== -1){
      //         questionAnswers[answer].shown = questions[y].shown;
      //         answers.push(questionAnswers[answer])
      //       } else if(localArrayQuestionAnswers){
      //         for(const t in this.state.localAnswersArray){
      //           if(this.state.localAnswersArray[t].answerId === questionAnswers[answer].id && this.state.localAnswersArray[t].questionId === questions[y].id){
      //             questionAnswers[answer].shown = questions[y].shown;
      //             answers.push(questionAnswers[answer])
      //           }
      //         }
      //       }
      //     }
      //   }
      //   questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions)
      // }
      console.log(this.state.localAnswersArray.length, questions.length)
      return(
          <>
            <div className="grid-wrapper" id="topOfPage">
              <div className="creator-awards-logo-playscreen user">
                <img src={topLeftImage} alt="" style={{marginBottom:"0", display: this.state.questionAnswered === false ? "":"none"}}/>
                <img src={topAnswerImage} alt="" style={{marginBottom:"0", display: this.state.questionAnswered === true ? "":"none"}}/>
              </div>
                    <div className="card" style={{borderRadius: "20px", margin: '0', padding: '0'}}>
                      {this.state.startAnimating && ((this.state.questionAnswered === false && localArrayQuestionAnswers === false) || editingQuestion) &&
                        <PoseGroup animateOnMount={true} delta={1} style={{margin: "auto", width: "100%"}}>
                          <Trans key={233}>
                            <div className="top-text">
                              <p className="top-text-mobile" style={{
                                wordWrap: "break-word",
                                fontWeight: 700,
                                color: questionTextColor || "white"
                              }}>{question.questionText}</p>
                            </div>
                            <AnswerItemsComponent show_percentages={show_percentages} question={question} answers={answerList} questionCount={questionCount} tenantVariables={tenantVariables} onClickAnswer={(item, i) => this.onClickAnswer(item, i)}/>
                          </Trans>
                        </PoseGroup>
                      }
                      {/*{(this.state.questionAnswered === true || localArrayQuestionAnswers ) && !editingQuestion &&*/}
                        <div className="intro-container-home" style={{marginTop:'20px'}}>
                          {this.state.questionAnswered && !editingQuestion &&
                              <div className="top-text">
                                <p className="top-text-mobile"><span className="emphasizedTextAnswers" style={{backgroundColor:primaryColor, color:secondaryColor}}>{myAnswersText}</span></p>
                                {this.state.editAnswers && gameStatus !== 1 && !this.state.rotationStarted && !this.state.gameOver &&
                                    <div>
                                      {editAnswersText}
                                    </div>
                                }
                              </div>
                          }
                          {/*{currentAnswerArray.length === 0 && this.state.editAnswers && localArrayQuestionAnswers && !editingQuestion &&*/}
                          {/*    <div>*/}
                          {/*      <button style={{color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginBottom: 10}} className="submitAnswersButtonStyles" onClick={()=> this.setAnswersPrompt()}>{stringConstants.SUBMITBUTTONTEXT}</button>*/}
                          {/*      <p>{stringConstants.EDITPICKTEXT}</p>*/}
                          {/*    </div>*/}
                          {/*}*/}
                          <PoseGroup animateOnMount={true} delta={1} style={{margin: "auto", width: "100%"}}>
                            <Trans key={233}>
                              <AnswerItemsComponent questions={questions} show_percentages={show_percentages} answers={answers} questionCount={questionCount} tenantVariables={tenantVariables} onClickAnswer={(item)=> this.state.editAnswers && this.goToSpecificQuestion(item)}/>
                            </Trans>
                          </PoseGroup>
                          <div className="container-out" id="more-info-form" style={{display: tenantVariables.formHeaderText === "" || hideAnswerForm || !tenantVariables.formHeaderText ? 'none': 'block'}}>
                            <div className="question-box question-form" style={{padding:'15px'}}>
                              <h4 style={{fontFamily: "Oswald", fontWeight: 700}}>{tenantVariables.formHeaderText}</h4>
                              <form onSubmit={this.handleSubmit} id="user-values-form">
                                <div className="input-group">
                                  <input id="fullName" name="fullName" type="text" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Full Name" />
                                </div>
                                <div className="input-group">
                                  <input id="phone" name="phone" type="tel" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Phone" />
                                </div>
                                <div className="input-group">
                                  <input id="zip" name="zip" type="text" pattern="[0-9]*" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Zip" />
                                </div>
                                <div className="checkbox" style={{padding:'5px'}}>
                                  <label className="agree_to_rules_class"><input type="checkbox" ref="agree_to_rules_regs"/> I Agree To <a target="_blank" href={this.state.tenantRules.rulesAndRegsLink}>Rules And Regulations</a></label>
                                </div>
                                <div className="checkbox" style={{padding:'5px'}}>
                                  <label className="want_emails"><input type="checkbox" ref="want_emails" defaultChecked/> I want to receive emails </label>
                                </div>
                                <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>SUBMIT</strong></button>
                              </form>
                            </div>
                          </div>
                        </div>
                      {/*// }*/}
                      <div style={{width: "100%", textAlign: "center"}}>
                        <img width="100%" src={sponsorLogo} alt="" style={{maxWidth: "380px"}}/>
                      </div>
                      <div className="mobilehide" style={{height: "100px", width: "1px"}}/>
                    </div>
                </div>
              <Modal isOpen={(this.state.modal && videoURL)} style={{width: '90%'}} id="myModal" toggle={this.toggle}>
                <ReactPlayer config={{file: { attributes : { controlsList: 'nodownload', disablePictureInPicture:true} } }} controls={true} pip={false} playing={this.state.modal} url={videoURL} playsinline={true} muted={true} volume={0} onEnded={this.onEnded} width="100%" height="auto"/>
              </Modal>
            </>
        )
    }

    handleSubmit(event){
      event.preventDefault();
      const stringConstants = this.props.stringConstants || {};
      const zip = this.state.zip.trim();
      const name = this.state.fullName.trim();
      const phone = this.state.phone.trim();
      const timesubmitted = Date.now();
      const receive_emails = this.refs.want_emails.checked;
      if(!this.refs.agree_to_rules_regs.checked){
          confirmAlert({
            variables: this.props.variables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.NOAGREERULESANDREGS,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          });
          return;
      }
      if(zip.length === 0 || name.length === 0 || phone === 0){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.PLEASEFILLOUTTHEFORMTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        });
        return;
      }
      const base64EncodedEmail = btoa(this.state.userEmail);
      const vm = this;
      const formData = {name: name, phone: phone, zip: zip, receive_emails: receive_emails, timeSubmitted: timesubmitted};
      base.post(`currentGameFormFilled/`+ this.state.currentGameId +'/' +base64EncodedEmail, {
        data: formData,
        then(err){
          if(!err){
            document.getElementById('more-info-form').style.display = "none";
            document.getElementById('thanks-for-playing-section').style.display = "";
          } else {
            console.log(err);
            confirmAlert({
                variables: vm.props.variables,
                title: stringConstants.ERRORTEXT,
                message: err.message,
                buttons: [
                  {
                    label: stringConstants.OKTEXT,
                  }
                ]
              })
          }
        }
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.questionAnswered && !this.state.questionAnswered){
        this.removeReactAlert();

        this.setState({
          startConfetti:false,
          immediateAnswersShow: false
        })
      }
    }

    render() {
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const stringConstants = this.props.stringConstants || {};
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const link = tenantRules.rulesAndRegsLink;
      const rulesShowInApp = tenantRules.rulesShowInApp || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!this.state.userEmail){
        let redirectString = "/login";
        let langague = "";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(window.location.pathname.split('/').length > 1){
          langague = "/" + splitWindowLocation[1]
          redirectString += langague
        }
        return (
            <Redirect to={redirectString} />
        )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
        this.logout();
      }
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
        let urlToGo = "/age_gate";
        let langauge = "/";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(splitWindowLocation.length > 1){
          langauge += splitWindowLocation[1];
          urlToGo += langauge
        }
        return (
            <Redirect to={urlToGo} />
        )
      }
      let renderMainScreen = this.renderHoldingScreen();
      if(this.state.active === true && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
            {this.state.startConfetti &&
              <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
            }
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto", width: "100%"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{margin:"auto",maxWidth: 800}}>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden", margin:10}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                    </div>
                    <div style={{margin:10, display: this.props.passedEmail && "none"}}>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.LOGOUTTEXT}</button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {rulesShowInAppPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            {/*<Modal isOpen={this.state.modal} style={{width: '90%'}} id="myModal" toggle={this.toggle}>*/}
            {/*  <ReactPlayer config={{file: { attributes : { controlsList: 'nodownload', disablePictureInPicture:true} } }} controls={true} pip={false} playing={this.state.modal} url={"https://www.youtube.com/embed/0Y5U2zkRW9g"} playsinline={true} muted={true} volume={0} onEnded={this.onEnded} width="100%" height="auto"/>*/}
            {/*</Modal>*/}
          </div>
      )
    }
}

export default Main_App;
