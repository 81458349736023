import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
// import * as serviceWorker from './serviceWorker';


ReactDOM.render(
    <App />,
  document.getElementById('root')

);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
        registration.unregister();

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async (names) => {
                await Promise.all(names.map(name => caches.delete(name)));
            });
        }
    });
}
// if(process.env.NODE_ENV === "production") {
//     serviceWorker.registerServiceWorker();
// }
